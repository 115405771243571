import store from "@/store";
import { isEmpty } from "lodash";
import { convLocaleString } from "@/utils/convert";
import { GraphicData } from "@/interfaces/persons/v10/person";
import { GraphicDataType, GraphicDataKey } from "@/interfaces/persons/v10/types";

/**
 * Obtener la estructura de datos para los graficos
 * @param type
 * @param key
 * @returns
 */
export function getGraphicData(type: GraphicDataType, key: GraphicDataKey) {
	const graphicData: GraphicData = store.getters["person/getGraphicResponse"](
		type,
		key
	);
	return graphicData;
}

/**
 * Obtener :data de la estructura de datos para los graficos
 * @param type
 * @param key
 * @returns
 */
export function getGraphicSource(type: GraphicDataType, key: GraphicDataKey) {
	const graphicData: GraphicData = getGraphicData(type, key);
	return graphicData?.data || [];
}

/**
 * Obtener :data de la estructura de datos para los graficos
 * @param type
 * @param key
 * @returns
 */
export function isLoadingSource(type: GraphicDataType, key: GraphicDataKey) {
	const graphicData: GraphicData = getGraphicData(type, key);
	return Boolean(graphicData?.loading);
}

/**
 * Obtener la estructura para mostrar el total de reach items
 * @param key 
 * @returns 
 */
export function getCountReachItems(key: GraphicDataKey) {
	const items = getGraphicSource(GraphicDataType.REACH, key);

	if (isEmpty(items)) return [];

	const total = items.map((i) => ({
		count: `${i.name}: ${convLocaleString(i.uniques)}`,
	}));

	return total;
}