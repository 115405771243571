import { convLocaleString } from "@/utils/convert";
import Vue from "vue";
import FilterCheckboxSkeleton from "@/components/Commons/Skeletons/FilterCheckbox/index.vue";

export default Vue.extend({
	name: "SelectedAll",
	model: {
		prop: "model_data",
		event: "input",
	},
	props: {
		model_data: {
			type: Boolean,
			default: false,
		},
		show: {
			type: Object,
			default: function () {
				return {
					total: true,
					checkbox: false,
				};
			},
		},
		title: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		total: {
			type: Number,
			default: 0,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	components: { FilterCheckboxSkeleton },
	data: () => ({}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			},
		},
	},
	methods: {
		convert(value: number, parenthesis: Boolean = true): string {
			if (parenthesis) return `(${convLocaleString(value)})`;
			return `${convLocaleString(value)}`;
		},
		handleClick() {
			this.$emit("click", this.modelData);
		},
	},
	watch: {
		modelData(val) {
			this.$emit("input", val);
		},
	},
});
