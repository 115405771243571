import Vue from "vue";

import ExpansionItem from "@/views/Admin/Persons/V10/Dependencies/Select/Commons/ExpansionItem/index.vue";
import {
	ExpansionItemEntity,
	getGeoExpansions,
} from "@/models/persons/v10/Tabs/Pois/Expansions/Pois";
import {
	PersonGeoKey,
	PersonKey,
	TypeFilterKey,
} from "@/interfaces/persons/v10/types";
import SelectFilter from "../../Mixins/SelectFilter";
import { debounce } from "lodash";
import { FILTER_KEY_MAPPING } from "@/models/persons/v10/Implements";
import { DELAY_FETCH } from "@/models/persons/v10/DataDefault";

export default Vue.extend({
	name: "SelectGeo",
	mixins: [SelectFilter],
	model: {},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	components: { ExpansionItem },
	data: () => ({
		expansionItems: [] as ExpansionItemEntity[],
		config: {
			type: PersonKey.GEO,
			key: TypeFilterKey.SELECTED,
		},
	}),
	created() {
		this.$nextTick(async () => {
			/**
			 * Load data structure
			 */
			this.expansionItems = await getGeoExpansions();

			/**
			 * Load the initial data for each item
			 */
			this.fetchExpansionItems();
		});
	},
	methods: {},
	watch: {
		/**
		 * GEO pre
		 */
		"getPerson.geo.pre.states": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(FILTER_KEY_MAPPING.geo.slice(-2));
				this.reFetchPoisPaginate();
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.geo.pre.cities": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(FILTER_KEY_MAPPING.geo.slice(-1));
				this.reFetchPoisPaginate();
			}, DELAY_FETCH),
			deep: true,
		},

		"getPerson.geo.selected.states"() {
			this.verifySelectedInResult(PersonGeoKey.STATES);
		},

		"getPerson.geo.selected.cities"() {
			this.verifySelectedInResult(PersonGeoKey.CITIES);
		},

		// "getPerson.geo.selected.neighborhoods"() {
		// 	this.verifySelectedInResult(PersonGeoKey.NEIGHBORHOODS);
		// },
	},
});
