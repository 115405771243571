import Vue from "vue";

export default Vue.extend({
	name: "GraphicContent",
	props: {
		icon: {
			type: String,
			default: "mdi-target"
		},
		title: {
			type: String,
			default: ""
		}
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {},
	methods: {},
	watch: {},
});
