import Vue, { PropType } from "vue";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardActions from "@/components/Content/CardAction.vue";
import { ComboListOptionsCampaign } from "@/utils/resolveObjectArray";

export default Vue.extend({
	name: "StoreAttribution",
	model: {
		prop: "model_data",
		event: "input",
	},
	props: {
		open: {
			type: Boolean,
			default: false,
		},
		check: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
		model_data: {
			type: Array as PropType<ComboListOptionsCampaign[]>,
			default: function () {
				return [];
			},
		},
		rules: {
			type: Array,
			default: function () {
				return [];
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	components: { DialogDefault, CardAutocomplete, CardActions },
	data: () => ({
		valid: true,
	}),
	created() {},
	async mounted() {},
	computed: {
		modelData: {
			set(val: ComboListOptionsCampaign[]) {
				this.$emit("input", val);
			},
			get(): ComboListOptionsCampaign[] {
				return this.model_data;
			},
		},
		checkedData: {
			set(val: Boolean) {
				this.$emit("check", val);
			},
			get(): Boolean {
				return this.check;
			},
		},
		disabledData() {
			const modelData: ComboListOptionsCampaign[] = this.modelData;
			return {
				submit: this.loading || modelData.length < 1,
				cancel: this.loading,
			};
		},
	},
	methods: {
		handleSubmit(event) {
			//console.log(`${this.$options.name}::handleSubmit`, { event });
		},
		handleAction(event: { type: string }) {
			const matched = { submit: "submit", cancel: "close" };
			const { type } = event;
			// console.log(`${this.$options.name}::handleAction`, {
			// 	event,
			// 	emit: matched[type],
			// });
			this.$emit(matched[type]);
		},
	},
	watch: {},
});
