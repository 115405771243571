var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"grey lighten-3",attrs:{"disabled":_vm.isGettingFromStored}},[_c('snack-message',{attrs:{"color":_vm.snackData.type},on:{"close":function($event){return _vm.snackData.setSnackData()}},model:{value:(_vm.snackData.message),callback:function ($$v) {_vm.$set(_vm.snackData, "message", $$v)},expression:"snackData.message"}}),_c('v-row',{staticClass:"mb-4"},[(false)?_vm._l((_vm.itemsExpansion),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"2"}},[_c('v-card',{attrs:{"elevation":"2","color":_vm.activePanel === item.id ? 'green' : 'grey darken-3',"dark":""}},[_c('v-card-title',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(item.type)+": "+_vm._s(_vm.activePanel)+" ")]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-subtitle',{staticClass:"py-1 d-flex",staticStyle:{"font-size":"0.7rem"}},[_vm._v(" Expanded "),_c('v-spacer'),_c('strong',[_vm._v(_vm._s(_vm.activePanel === item.id)+": "+_vm._s(_vm.isExpanded(item.id)))])],1),_c('v-card-subtitle',{staticClass:"py-1 d-flex",staticStyle:{"font-size":"0.7rem"}},[_vm._v(" ID "),_c('v-spacer'),_c('strong',[_vm._v(_vm._s(item.id))])],1),_c('v-card-subtitle',{staticClass:"py-1 d-flex",staticStyle:{"font-size":"0.7rem"}},[_vm._v(" Type "),_c('v-spacer'),_c('strong',[_vm._v(_vm._s(item.type))])],1)],1)],1)}):_vm._e(),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-expansion-panels',{staticClass:"collapse-panels",attrs:{"accordion":true,"flat":"","tile":""},model:{value:(_vm.activePanel),callback:function ($$v) {_vm.activePanel=$$v},expression:"activePanel"}},[_vm._l((_vm.itemsExpansion),function(item){return [_c('card-collapse-panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSection(item)),expression:"showSection(item)"}],key:`tab_expansion_${item.id}`,attrs:{"item":item,"expanded":_vm.isExpanded(item.id)}},[(
								_vm.isSection(
									item.type,
									_vm.getPersonKey.COUNTRY_GLOBAL
								)
							)?[_c('select-country',{attrs:{"isActive":_vm.isTabActive(
										_vm.getActivePanelTab.COUNTRY_GLOBAL
									)}})]:_vm._e(),(
								_vm.isSection(item.type, _vm.getPersonKey.STRATEGY)
							)?[_c('select-strategy',{attrs:{"isActive":_vm.isTabActive(_vm.getActivePanelTab.STRATEGY)}})]:_vm._e(),(
								_vm.isSection(
									item.type,
									_vm.getPersonKey.USES_CASES
								)
							)?[_c('select-use-case',{attrs:{"isActive":_vm.isTabActive(
										_vm.getActivePanelTab.USES_CASES
									)}})]:_vm._e(),(_vm.isSection(item.type, _vm.getPersonKey.GEO))?[_c('select-geo',{attrs:{"isActive":_vm.isTabActive(_vm.getActivePanelTab.GEO)}})]:_vm._e(),(_vm.isSection(item.type, _vm.getPersonKey.POIS))?[_c('select-pois',{attrs:{"isActive":_vm.isTabActive(_vm.getActivePanelTab.POIS)}})]:_vm._e(),(
								_vm.isSection(item.type, _vm.getPersonKey.PRIVATE)
							)?[_c('select-private',{attrs:{"isActive":_vm.isTabActive(_vm.getActivePanelTab.PRIVATES)}})]:_vm._e(),(_vm.isSection(item.type, _vm.getPersonKey.OOH))?[_c('select-ooh',{attrs:{"isActive":_vm.isTabActive(_vm.getActivePanelTab.OOH)}})]:_vm._e()],2)]})],2)],1)],2),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},_vm._l((_vm.getActions),function(item){return _c('v-col',{key:item.type},[_c('card-action',{attrs:{"item":item},on:{"action":_vm.handleAction},scopedSlots:_vm._u([(_vm.showReach(item.type))?{key:"reach",fn:function(){return _vm._l((_vm.getCount(item.type)),function(chip,index){return _c('v-chip',{key:index,staticClass:"ml-1",attrs:{"color":_vm.getChipColor(item.type),"dark":"","small":"","loading":item.loading}},[_vm._v(" "+_vm._s(chip.count)+" ")])})},proxy:true}:null],null,true)})],1)}),1),(_vm.getPersonComputed.hasCountry())?_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('graphic-content',{attrs:{"icon":"mdi-map","title":_vm.$t('persons.v10.panelsPois.titles.map')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('geo-analysis',{ref:"pois_carto",on:{"onSync":_vm.syncGeoAnalysis}})]},proxy:true}],null,false,3209160982)})],1)],1):_vm._e(),_c('v-row',{staticClass:"mb-4"},[(_vm.isAnalizedPois)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('graphic-content',{attrs:{"icon":"mdi-account-multiple-outline","title":_vm.$t('graphics.pois.geo.title')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"20px"}},_vm._l((_vm.getGraphicDataPois),function(graphic,index){return _c('card-grouped',{key:index,staticStyle:{"max-width":"100%","flex":"1 0 450px"},attrs:{"textColor":"#444444","infoData":graphic.infoData,"source":_vm.getGraphicSource(
									_vm.getGraphicDataType.POIS,
									graphic.key
								),"loading":_vm.isLoadingSource(
									_vm.getGraphicDataType.POIS,
									graphic.key
								),"hide_filter":true},on:{"input":_vm.handleInput}})}),1)]},proxy:true}],null,false,3420782447)})],1):_vm._e(),(_vm.isAnalizedGeo && false)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('graphic-content',{attrs:{"icon":"mdi-account-multiple-outline","title":_vm.$t('graphics.pois.pois.title')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',_vm._l((_vm.getGraphicDataGeo),function(graphic,index){return _c('v-col',{key:index,attrs:{"cols":"12","lg":"4"}},[_c('card-grouped',{attrs:{"textColor":"#444444","infoData":graphic.infoData,"source":_vm.getGraphicSource(
										_vm.getGraphicDataType.GEO,
										graphic.key
									),"loading":_vm.isLoadingSource(
										_vm.getGraphicDataType.GEO,
										graphic.key
									),"hide_filter":true},on:{"input":_vm.handleInput}})],1)}),1)]},proxy:true}],null,false,2460853649)})],1):_vm._e()],1),_c('app-store-attribution',{attrs:{"open":_vm.getPersonComputed.store_attribution.open,"check":_vm.getPersonComputed.store_attribution.check,"items":_vm.getCampaignListComputed,"loading":_vm.getPersonComputed.store_attribution.loading},on:{"submit":_vm.handleStoreAttributionSubmit,"close":_vm.handleStoreAttributionClose,"check":_vm.handleStoreAttributionCheck},model:{value:(_vm.getPersonComputed.store_attribution.selected),callback:function ($$v) {_vm.$set(_vm.getPersonComputed.store_attribution, "selected", $$v)},expression:"getPersonComputed.store_attribution.selected"}}),_c('AudienceExport',{attrs:{"show":_vm.getPersonComputed.target_audience.open},on:{"export":_vm.handleTargetAudienceSubmit,"close":_vm.handleTargetAudienceClose}}),_c('SavePois',{attrs:{"show":_vm.getPersonComputed.save_pois.open},on:{"submit":_vm.handleSavePoisSubmit,"close":_vm.handleSavePoisClose}}),_c('GeoFencing',{attrs:{"show":_vm.getPersonComputed.geo_fencing.open},on:{"submit":_vm.handleGeoFencingSubmit,"close":_vm.handleGeoFencingClose}}),(_vm.canCreateOOHNotification)?_c('ActivateOOH',{attrs:{"show":_vm.getPersonComputed.activate_ooh.open,"loading":_vm.getPersonComputed.activate_ooh.loading},on:{"close":_vm.handleActivateOOHClose}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }