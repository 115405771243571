export interface ListLat {
	name: string;
	radio?: number;
}

export class ListLatEntity implements ListLat {
	name: string = "";
	radio?: number | undefined;

	constructor(parametters?: ListLat) {
		this.name = parametters?.name ?? "";
		this.radio = parametters?.radio;
	}
}
