import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
	name: "CardAction",
	props: {
		item: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters("person", ["getLoading", "getPerson"]),
		getTitle() {
			return this.item.title;
		},

		getIcon() {
			return this.item.icon;
		},

		isLoading() {
			return this.item.loading;
		},

		// isDisabled(): Boolean {
		// 	let disabled: Boolean = false;

		// 	switch (this.item.type) {
		// 		case "analize_pois":
		// 			disabled = !this.getPerson.isEnableAnalizePois();
		// 			break;

		// 		case "calculate_reach":
		// 			disabled = !this.getPerson.isAnalizedPois();
		// 			break;
		// 	}

		// 	if (!disabled) {
		// 		disabled = this.item.disabled || this.isLoading;
		// 	}

		// 	return disabled;
		// },
	},
	methods: {
		async handleAction() {
			this.$emit("action", { type: this.item.type });
		},
	},
	watch: {},
});
