import Vue from "vue";
export default Vue.extend({
	name: "FilterCheckboxSkeleton",
	model: {},
	props: {
		count: {
			type: Number,
			default: 12,
		},
	},
	components: {},
	data: () => ({}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {},
	computed: {},
	methods: {},
	watch: {},
});
