import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2ddf1424"
import script from "@/views/Admin/Persons/V10/Dependencies/Common/Graphic/Content/index.ts?vue&type=script&lang=ts&external"
export * from "@/views/Admin/Persons/V10/Dependencies/Common/Graphic/Content/index.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports