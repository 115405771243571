export interface DataFilter {
	type: "SUBMIT" | "UPDATE";
	key?:
		| "_country"
		| "_state"
		| "_municipalities"
		| "_neighborhood"
		| "_poisCategory"
		| "_poisSubCategory"
		| "_poisMarca"
		| "_poisName"
		| "_likeName";
	value?: string[]|number[];
}

export interface PostMessageEvent {
	type: "SUBMIT" | "UPDATE";
	data: {
		_country?: number[];
		_state?: string[];
		_municipalities?: string[];
		_neighborhood?: string[];
		_poisCategory?: string[];
		_poisSubCategory?: string[];
		_poisMarca?: string[];
		_poisName?: string[];
	};
}

export enum DataType {
	SUBMIT = "SUBMIT",
	UPDATE = "UPDATE",
}

export interface PasingFilter {
	_country?: string[];
	_state?: string[];
	_municipalities?: string[];
	_neighborhood?: string[];
	_poisCategory?: string[];
	_poisSubCategory?: string[];
	_poisMarca?: string[];
	_poisName?: string[];
}
