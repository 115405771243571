import { GraphicDataKey, GraphicDataType } from "@/interfaces/persons/v10/types";
import i18n from "@/plugins/i18n";

export const graphicDataItems = {
	geo: (country_id: number) => ([
		getGraphicData("pois", GraphicDataType.GEO, GraphicDataKey.STATES, country_id),
		getGraphicData("pois", GraphicDataType.GEO, GraphicDataKey.CITIES, country_id),
		getGraphicData("pois", GraphicDataType.GEO, GraphicDataKey.NEIGHBORHOODS, country_id),
	]),
	pois: (country_id: number) => ([
		getGraphicData("pois", GraphicDataType.POIS, GraphicDataKey.POIS),
		getGraphicData("pois", GraphicDataType.POIS, GraphicDataKey.CATEGORIES),
		getGraphicData("pois", GraphicDataType.POIS, GraphicDataKey.SUBCATEGORIES),
		getGraphicData("pois", GraphicDataType.POIS, GraphicDataKey.BRANDS),
		getGraphicData("pois", GraphicDataType.POIS, GraphicDataKey.STATES, country_id),
		getGraphicData("pois", GraphicDataType.POIS, GraphicDataKey.CITIES, country_id),
		getGraphicData("pois", GraphicDataType.POIS, GraphicDataKey.NEIGHBORHOODS, country_id),
	]),
};
/**
 * Graphic Data
 * @param tab pois|audience
 * @param type geo|pois
 * @param key states|cities|...
 * @returns
 */
export function getGraphicData(tab: string, type: GraphicDataType, key: GraphicDataKey, country_id?: number) {
	const infoDataName = `graphics.${tab}.${type}.${key}`;
	const name = i18n.te(`persons.v10.panelsPois.geo.${country_id}.${key}`) 
		? i18n.t(`persons.v10.panelsPois.geo.${country_id}.${key}`) 
		: i18n.t(`persons.v10.panelsPois.geo.840.${key}`);
	return {
		key: key,
		infoData: {
			id: `${type}_${key}`,
			name: i18n.t(infoDataName, { name: String(name).toLowerCase() }),
		},
	};
}
