import { ElementData } from "@/interfaces/persons/v10/person";
import { PersonKey } from "@/interfaces/persons/v10/types";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
	name: "SelectRadioCountry",
	model: {},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		...mapGetters("person", ["getPerson", "getQueryParams", "getResource"]),
		selectedData: {
			get(): ElementData {
				const selectedData: ElementData = this.getPerson?.getByKey(
					PersonKey.COUNTRY_GLOBAL
				);
				return {
					id: selectedData.id,
					value: selectedData.value,
				};
			},
			set(val: ElementData) {
				this.getPerson.setByKey(PersonKey.COUNTRY_GLOBAL, val);
			},
		},
		getCountries() {
			return this.getResource({ key: "countries" });
		},
	},
	methods: {
		...mapActions("person", ["fetchData", "setResource"]),
		isChecked(item: ElementData) {
			return this.selectedData.id === item.id;
		},
		getNameCountry(item: any) {
			const st = `persons.v10.panelsPois.geo.${item.id}.name`;
			return this.$t(st);
		},
		getContinent(title: string) {
			const st = `persons.v10.continent.${title}`;
			return this.$t(st);
		},
	},
	watch: {
		// isActive(val, old) {
		// 	console.log(`${this.$options.name}::isActive`, { val, old });
		// },
	},
});
