import Vue from "vue";
//@ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
import { getError } from "@/utils/resolveObjectArray";
//@ts-ignore
import Alertize from "@/components/Alertize.vue";
//@ts-ignore
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import CardActions from "@/components/Content/CardActions.vue";
import { mapGetters } from "vuex";
import { isRequired } from "@/services/rule-services";
import { ListLatEntity } from "./util";

export default Vue.extend({
	name: "GeoFencing",

	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		CardTextField,
		Alertize,
		DialogDefault,
		CardActions,
	},

	data: () => ({
		valid: true,
		exportListLat: new ListLatEntity(),
		rules: {
			export_name: [],
			export_radio: [],
		},
	}),

	created() {},

	async mounted() {
		this.$nextTick(async () => {});
	},

	computed: {
		...mapGetters("person", ["getPersistent"]),
		...mapGetters("loading", {
			isLoading: "isLoading",
		}),

		getCountryName() {
			return this.getPersistent.country_global.value;
		},

		getErrors() {
			return this.$store.state.proccess.errors;
		},
	},

	methods: {
		getErrorMessage(index: any) {
			return getError(this.getErrors, index);
		},

		applyRules() {
			this.rules = {
				export_name: [
					(value) => {
						if (!value) return this.$t("fieldRequired");

						return true;
					},
				],
				export_radio: [
					(value) => {
						if (!value) return this.$t("fieldRequired");
						let num = parseFloat(value);
						if (num < 0.001 || num > 50)
							return this.$t("privatepois.export.hint.radio");

						return true;
					},
				],
			};
		},

		clearRules() {
			this.rules = {
				export_name: [],
				export_radio: [],
			};
		},

		reset() {
			this.exportListLat = new ListLatEntity();
			this.clearRules();
		},

		handleAction(data: { type: "save" | "cancel" }) {
			if (data.type === "save") {
				this.handleSubmit();
			}
			if (data.type === "cancel") {
				this.closeModal();
			}
		},

		closeModal() {
			this.clearRules();
			this.$emit("close");
		},

		async handleSubmit() {
			try {
				await this.applyRules();
				if (!(await this.validate())) return;

				this.$emit("submit", {
					name: this.exportListLat.name,
					radio: parseFloat(this.exportListLat.radio),
				});

				this.reset();
			} catch (error) {
				this.clearRules();
			}
		},

		async validate() {
			let form = this.$refs.formExport;
			const valid = await form.validate();
			return await valid;
		},
	},

	watch: {},
});
