import { ElementData } from "@/interfaces/persons/v10/person";
import {
	PersonFilterType,
	TypeFilterKey,
} from "@/interfaces/persons/v10/types";
import { PersonEntity } from "@/models/persons/v10/Person";

export function buildDataParams(
	configType: PersonFilterType,
	person: PersonEntity,
	key: PersonFilterType,
	isGet: Boolean
): Record<string, any> {
	const countryGlobal: ElementData = person.country_global;

	let params = {
		key,
		type: key,
		country_global: countryGlobal,
	};

	if (isGet) {
		Object.assign(params, { pre: getPreFilterPois(person, configType) });
	}

	return params;
}

function getPreFilterPois(
	person: PersonEntity,
	key: PersonFilterType
): PersonFilterType {
	return person[key][TypeFilterKey.PRE];
}
