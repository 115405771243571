import Vue from "vue";

import ExpansionItem from "@/views/Admin/Persons/V10/Dependencies/Select/Commons/ExpansionItem/index.vue";
import {
	ExpansionItemEntity,
	getPoisExpansions,
} from "@/models/persons/v10/Tabs/Pois/Expansions/Pois";
import {
	PersonKey,
	PersonPoisKey,
	TypeFilterKey,
} from "@/interfaces/persons/v10/types";
import SelectFilter from "../../Mixins/SelectFilter";
import { debounce } from "lodash";
import { DELAY_FETCH } from "@/models/persons/v10/DataDefault";
import { FILTER_KEY_MAPPING } from "@/models/persons/v10/Implements";

export default Vue.extend({
	name: "SelectPois",
	mixins: [SelectFilter],
	model: {},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	components: { ExpansionItem },
	data: () => ({
		expansionItems: [] as ExpansionItemEntity[],
		config: {
			type: PersonKey.POIS,
			key: TypeFilterKey.SELECTED,
		},
	}),
	created() {
		this.$nextTick(async () => {
			/**
			 * Load data structure
			 */
			this.expansionItems = await getPoisExpansions();

			/**
			 * Load the initial data for each item
			 */
			this.fetchExpansionItems();
		});
	},
	methods: {},
	watch: {
		"getPerson.pois.pre.categories": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(FILTER_KEY_MAPPING.pois.slice(-3));
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.pois.pre.subcategories": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(FILTER_KEY_MAPPING.pois.slice(-2));
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.pois.pre.brands": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(FILTER_KEY_MAPPING.pois.slice(-1));
			}, DELAY_FETCH),
			deep: true,
		},
		
		"getPerson.pois.selected.categories"() {
			this.verifySelectedInResult(PersonPoisKey.CATEGORIES);
		},
		"getPerson.pois.selected.subcategories"() {
			this.verifySelectedInResult(PersonPoisKey.SUBCATEGORIES);
		},
		"getPerson.pois.selected.brands"() {
			this.verifySelectedInResult(PersonPoisKey.BRANDS);
		},
		// "getPerson.pois.selected.names"() {
		// 	this.verifySelectedInResult(PersonPoisKey.NAMES);
		// },
	},
});
