import { ResultElementData } from "@/interfaces/paginated";
import { ElementData } from "@/interfaces/persons/v10/person";
import { PRESENCE_DELAY_AT } from "@/interfaces/persons/v10/query/global";
import {
	ActivePanelTab,
	PersonGeoKey,
	PersonKey,
	PersonOohKey,
	PersonPoisKey,
	PersonPrivateKey,
} from "@/interfaces/persons/v10/types";
import i18n from "@/plugins/i18n";
import { orderBy } from "lodash";
import { TranslateResult } from "vue-i18n";

export class ResultElementDataEntity implements ResultElementData {
	total: number = 0;
	data: ElementData[] = [];
	current_page: number = 1;
}

export interface ExpansionItem {
	type: PersonKey;
	key: PersonPoisKey | PersonGeoKey | PersonPrivateKey | PersonOohKey;
	title: TranslateResult;
	loading: Boolean;
	selecting: Boolean;
	resultData: ResultElementData;
	setLoading(loading: Boolean): void;
	setSelecting(selecting: Boolean): void;
	setTotal(total: number): void;
	setCurrentPage(page: number): void;
	setPaginationData(data: ElementData[], page: number, total: number): void;
	loadMoreToResult(resultData: ResultElementData): void;
	setNewToResult(resultData: ResultElementData): void;
	addNewDataToResult(resultData: ResultElementData): void;
}

export interface ExpansionPanel {
	id: ActivePanelTab;
	title: string;
	icon: string;
	type: PersonKey;
	disabled: Boolean;
	expandable: Boolean;
	required: Boolean;
	loading: Boolean;
	verify: Boolean;
	show?: Boolean;
}

export enum ResultType {
	SEARCH = "search",
	LOAD_MORE = "load_more",
	FILTERING = "filtering",
	NEW = "new",
}

export class ExpansionItemEntity implements ExpansionItem {
	type: PersonKey = PersonKey.NONE;
	key: PersonPoisKey | PersonGeoKey | PersonPrivateKey | PersonOohKey =
		PersonPoisKey.CATEGORIES;
	title: TranslateResult = "";
	loading: Boolean = false;
	selecting: Boolean = false;
	resultData: ResultElementData = new ResultElementDataEntity();

	constructor(
		type: PersonKey,
		key: PersonPoisKey | PersonGeoKey | PersonPrivateKey | PersonOohKey,
		title: TranslateResult
	) {
		this.type = type;
		this.key = key;
		this.title = title;
		this.loading = false;
	}

	getCount() {
		return this.resultData.data.length;
	}

	enableSelecting() {
		return this.getCount() > PRESENCE_DELAY_AT;
	}

	setLoading(loading: Boolean = false) {
		this.loading = loading;
	}

	async setSelecting(selecting: Boolean = false) {
		this.selecting = selecting;
	}

	setTotal(total: number = 0) {
		this.resultData.total = total;
	}

	async setCurrentPage(page: number = 1) {
		this.resultData.current_page = page;
	}

	/**
	 * Set pagination data
	 * @param data
	 * @param page
	 * @param total
	 */
	async setPaginationData(data: ElementData[], page: number, total: number) {
		this.resultData.data = data;
		this.resultData.current_page = page;
		this.resultData.total = total;
	}

	async asignPaginationData(
		resultData: ResultElementData,
		data: ElementData[]
	) {
		const total = resultData.total;
		const page = resultData.current_page;

		return await this.setPaginationData(data, page, total);
	}

	/**
	 * Agregar datos a los ya existentes
	 * type: LOAD_MORE
	 */
	async loadMoreToResult(resultData: ResultElementData) {
		// get merged data
		let getData: ElementData[] = await mergeTwoArrays(
			this.resultData.data,
			resultData.data
		);

		// get ordered data
		getData = await orderResult(getData);

		// asign pagination data
		await this.asignPaginationData(resultData, getData);
	}

	/**
	 * Agregar nuevos datos
	 * type: SEARCH
	 * @param resultData
	 */
	async setNewToResult(resultData: ResultElementData) {
		// get ordered data
		let getData: ElementData[] = await orderResult(resultData.data);

		// asign pagination data
		await this.asignPaginationData(resultData, getData);
	}

	isEqualIdent(item: ElementData, compare: ElementData) {
		return item.identifier === compare.identifier;
	}

	hasSomeElementData(elements: ElementData[], item: ElementData) {
		return elements.some((e) => e.identifier === item.identifier);
	}

	/**
	 * Agregar datos sin repetir con los datos existentes
	 * type: FILTERING
	 * @param resultData
	 */
	async addNewDataToResult(resultData: ResultElementData) {
		let existings = this.resultData.data.filter((d) =>
			resultData.data.some((n) => this.isEqualIdent(n, d))
		);

		// obtener los elementos nuevos
		let newElements = resultData.data.filter(
			(d) => !this.resultData.data.some((n) => this.isEqualIdent(n, d))
		);

		// get merged data
		let getData: ElementData[] = await mergeTwoArrays(
			existings,
			newElements
		);

		// get ordered data
		getData = await orderResult(getData);

		// asign pagination data
		await this.asignPaginationData(resultData, getData);
	}
}

/**
 * Combine and set unique elements
 * @param elements
 * @returns
 */
export async function mergeUniques(elements: ElementData[]) {
	return Array.from(new Set([...elements]));
}

/**
 * Combine 2 arrays and set unique elements
 * @param elements
 * @param newElements
 * @returns
 */
export async function mergeTwoArrays(
	elements: ElementData[],
	newElements: ElementData[]
) {
	return Array.from(new Set([...elements, ...newElements]));
}

export async function orderResult(elements: ElementData[]) {
	return orderBy(elements, ["value"], ["asc"]);
}

/**
 * Get unique and common elements in 2 arrays
 * @param elements
 * @param newElements
 * @returns
 */
export async function getExistingElement(
	elements: ElementData[],
	newElements: ElementData[]
) {
	return newElements.filter((n) => this.hasSomeElementData(elements, n));
}

export function setExpansionItem(
	type: PersonKey,
	key: PersonPoisKey | PersonGeoKey | PersonPrivateKey | PersonOohKey
) {
	const title = `persons.v10.panelsPois.subPanel.${type}.${key}`;
	return new ExpansionItemEntity(type, key, i18n.t(title));
}

export async function getPoisExpansions() {
	return [
		setExpansionItem(PersonKey.POIS, PersonPoisKey.CATEGORIES),
		setExpansionItem(PersonKey.POIS, PersonPoisKey.SUBCATEGORIES),
		setExpansionItem(PersonKey.POIS, PersonPoisKey.BRANDS),
		setExpansionItem(PersonKey.POIS, PersonPoisKey.NAMES),
	];
}

export async function getPrivateExpansions() {
	return [setExpansionItem(PersonKey.PRIVATE, PersonPrivateKey.PRIVATES)];
}

export async function getGeoExpansions() {
	return [
		setExpansionItem(PersonKey.GEO, PersonGeoKey.STATES),
		setExpansionItem(PersonKey.GEO, PersonGeoKey.CITIES),
		setExpansionItem(PersonKey.GEO, PersonGeoKey.NEIGHBORHOODS),
	];
}

export async function getOohExpansions() {
	return [
		setExpansionItem(PersonKey.OOH, PersonOohKey.OOH_CATEGORIES),
		setExpansionItem(PersonKey.OOH, PersonOohKey.OOH_SUBCATEGORIES),
		setExpansionItem(PersonKey.OOH, PersonOohKey.OOH_BRANDS),
		setExpansionItem(PersonKey.OOH, PersonOohKey.OOH_NAMES),
	];
}
