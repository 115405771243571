import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DispatchMixin from "@/mixins/Dispatch";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import CardAction from "@/components/Content/CardAction.vue";
import { CardActionType } from "@/interfaces/action";
import { sleep } from "@/utils/convert";
import { PersonEntity } from "@/models/persons/v10/Person";
import { Getter } from "vuex-class";

@Component({
	components: { DialogDefault, CardAction },
	mixins: [DispatchMixin],
})
export default class ActivateOOH extends Vue {
	@Prop({ default: false }) private show!: boolean;

	@Getter("person/getPerson") getPerson!: PersonEntity;

	private loading: boolean = false;

	public get isOpen() {
		return this.show;
	}

	public get isLoading() {
		return this.loading;
	}

	public setLoading(loading: boolean) {
		this.loading = loading;
	}

	public dispatchStore!: <T>(moduleFunc: string, data: T) => Promise<any>;

	// Método para manejar acciones
	public async handleAction(action: { type: CardActionType }): Promise<void> {
		switch (action.type) {
			case CardActionType.CANCEL:
				await this.closeEmit();
				break;
			case CardActionType.RUN:
				await this.handleActivateOOHSubmit();
				break;
		}
	}

	private async handleActivateOOHSubmit() {
		try {
			this.setLoading(true);
			await this.privatedispatchActivateOOH();
			this.setLoading(false);
			await this.closeEmit();
		} catch (error) {
			console.error("ActivateOOH::handleActivateOOHSubmit", error);
			this.setLoading(false);
		}
	}

	private async privatedispatchActivateOOH() {
		const payload = await this.getPerson.getActivateOOHPayload();
		return await this.dispatchStore("person/activateOOH", payload);
	}

	// @Emit("submit")
	// public async submitEmit() {
	// 	return { type: CardActionType.RUN };
	// }

	@Emit("close")
	public async closeEmit() {
		return false;
	}
}
